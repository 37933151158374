import { css } from '@emotion/react';

export const h1 = css`
    font-size: 3rem;
    line-height: 150%;
    font-weight: 400;
    font-family: 'Sacramento', cursive;

    @media (min-width: 1280px) {
        font-size: 5rem;
    }
`;

export const h2 = css`
    font-size: 2.5rem;
    line-height: 152.5%;
    font-weight: 500;
    font-family: 'Playfair Display', serif;

    @media (min-width: 1280px) {
        font-size: 3rem;
    }
`;

export const h3 = css`
    font-size: 2rem;
    line-height: 152.5%;
    font-weight: 500;
    font-family: 'Playfair Display', serif;

    @media (min-width: 1280px) {
        font-size: 2.5rem;
        line-height: 155.5%;
    }
`;

export const h4 = css`
    font-size: 1.5rem;
    line-height: 152.5%;
    font-weight: 500;
    font-family: 'Playfair Display', serif;

    @media (min-width: 1280px) {
        font-size: 2rem;
        line-height: 162%;
    }
`;

export const h5 = css`
    font-size: 1.25rem;
    line-height: 152.5%;
    font-weight: 600;
    font-family: 'Playfair Display', serif;

    @media (min-width: 1280px) {
        font-size: 1.5rem;
        line-height: 162%;
    }
`;

export const smTxt = css`
    font-size: 0.875rem;

    @media (min-width: 1280px) {
        font-size: 1rem;
    }
`;

export const lgTxt = css`
    font-size: 1.125rem;

    @media (min-width: 1280px) {
        font-size: 1.25rem;
    }
`;
