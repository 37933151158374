import { css, Global, Theme } from '@emotion/react';
import '@fontsource/lato';
import '@fontsource/lato/700.css';
import '@fontsource/playfair-display/500.css';
import '@fontsource/playfair-display/600.css';
import '@fontsource/sacramento';
import emotionNormalize from 'emotion-normalize';
import { h1, h2, h3, h4, h5 } from './Typography';

export const theme: Theme = {
    colors: {
        white: '#fff',
        black: '#232735',
        gray: '#4F4C4C',
        blue: '#7CB0D7',
        green: '#84C7B1',
        greenLight: '#E1F0EB',
        gold: '#D9BA52',
        goldLight: 'rgba(234, 225, 215, 0.8)',
    },
    spacing: {
        md: '2.22vw', //32px at 1440px wide viewport
        lg: '4.45vw', //64px ^^
        xl: '6.67vw', //96px ^^
        xxl: '8.89vw', //128px ^^
    },
};

export const GlobalStyles = () => {
    return (
        <Global
            styles={() => css`
                ${emotionNormalize};

                html {
                    font-size: 100%;
                    overflow-x: hidden;
                    scroll-behavior: smooth;
                }
                body {
                    color: ${theme.colors.black};
                    font-family: 'Lato', sans-serif;
                    font-weight: normal;
                    font-size: 1rem;
                    line-height: 152.5%;
                    word-wrap: break-word;
                    font-kerning: normal;
                    word-wrap: break-word;
                    -webkit-font-smoothing: antialiased;
                }

                h1 {
                    ${h1};
                }

                h2 {
                    ${h2};
                }

                h3 {
                    ${h3};
                }

                h4 {
                    ${h4};
                }

                h5 {
                    ${h5};
                }

                a {
                    text-decoration: none;
                    color: ${theme.colors.black};
                    outline: none;
                    background-color: transparent;
                    -webkit-text-decoration-skip: objects;
                }

                *,
                *:before,
                *:after {
                    box-sizing: border-box;
                }

                img {
                    max-width: 100%;
                    display: block;
                    margin: 0;
                    padding: 0;
                }

                ul,
                ol {
                    padding-inline-start: 24px;
                }

                @media (min-width: 1280px) {
                    body {
                        font-size: 1.125rem;
                        line-height: 162%;
                    }
                }
            `}
        />
    );
};
